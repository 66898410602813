<template>
  <div class="form-stepper form-stepper-campaign">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
      @submit.prevent
    >
      <p class="mb-0">{{ $t('CAMPAIGN.DATES_TITLE') }}</p>
      <p><small class="grey--text">{{ $t('CAMPAIGN.DATES_TEXT') }}</small></p>

      <div>
        <v-card flat outlined class="pt-4">
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <p class="ml-4">{{$t('INPUT.DATE_START')}} <span class="grey--text" v-if="start_date">{{$moment(start_date).fromNow()}}</span></p>
              <v-text-field
                class="ml-4 mr-4"
                dense
                v-model="start_date"
                :rules="[$rules.required]"
                readonly
                outlined
                clearable
              />
              <v-date-picker
                :locale="$i18n.locale"
                color="primary"
                width="100%"
                ref="picker"
                no-title
                flat
                v-model="start_date"
                :min="new Date().toISOString().substr(0, 10)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <p class="ml-4">{{$t('INPUT.DATE_END')}} <span class="grey--text" v-if="end_date">{{$moment(end_date).fromNow()}}</span></p>
              <v-text-field
                class="ml-4 mr-4"
                dense
                v-model="end_date"
                :label="$t('INPUT.DATE_END')"
                :rules="[$rules.required]"
                readonly
                outlined
                clearable
              />
              <v-date-picker
                :locale="$i18n.locale"
                color="primary"
                width="100%"
                ref="picker"
                no-title
                flat
                v-model="end_date"
                :min="start_date"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>

    </v-form>

    <v-divider />
    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('COMMON.CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>

    </v-toolbar>

  </div>
</template>

<script>
export default {
  name: 'FormCampaignStepperIntro',
  data: () => ({
    loading: false,
    valid: false,
    start_date: new Date().toISOString().slice(0,10),
    end_date: new Date(+new Date() + 1000 * 60 * 60 * 24 * 365).toISOString().slice(0,10)
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      Object.keys(this.values)
        .forEach((key) => {
          if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
            this[key] = this.values[key]
          }
        })
    },
    validate () {
      if (!this.$refs.form.validate()) {
        this.$emit('validation', false)
        return
      }
      const data = {}
      data.start_date = this.start_date
      data.end_date = this.end_date

      this.$emit('childData', data)
      this.$emit('validation', true)
      this.$emit('next')
    },
  },
}
</script>

<style scoped>

</style>
